import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { getOneCompany } from '../../api/CompanyApi';

import { URLS } from '../../utils/urls.util';
import { useApi } from '../../hooks/useApi';

import { getAppSettings } from '../../api/AppSettings';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { CompanySettingsValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FormValidator } from '../FormValidator/FormValidator';

export const SingleCompanySettings = ({ id, permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getAppSettingsApi = useApi(getAppSettings);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [wasChange, setChange] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [validateErrors, setValidateErrors] = useState([]);
  const [settingsData, setSettingsData] = useState({});

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {company.title}
    </Typography>,
    <Typography key="3" color="text.primary">
      Настройки
    </Typography>,
  ];

  const config = useMemo(() => CompanySettingsValidationConfig(), []);

  const handlerError = (data) => {
    setValidateErrors(data);
  };

  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi.sendRequest(id, ['meta']).then((company) => {
        setCompany(company);
        setSettingsData({
          Id: company.id,
          IsItvEnabled: company.IsItvEnabled || false,
          MaxCameraCount: company.MaxCameraCount || 1,
          ItvServiceUrl: company.ItvServiceUrl || 'https://'
        });
      });
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
  }, [id]);

  return (
    <FullPageLayout
      obj={company}
      seoTitle={'Настройки - ' + company?.title}
      pageTitle={company.title}
      isCreated={false}
      breadcrumbs={breadcrumbs}
      showHiddenData={true}
    >
      <SingleCompanyNavigation
        company={company}
        activeTab={6}
        wasChange={wasChange}
        appSettings={appSettings}
      />
      <FormValidator
        name="company-settings-form-name"
        validationConfig={config}
        changed={settingsData}
        setErrorsHandler={(data) => handlerError(data)}
      >
        <Typography sx={{ mt: 3 }} variant="h5">
          Настройки
        </Typography>
        {!!Object.keys(settingsData)?.length && (
          <FormControlLabel
            sx={{ mt: 3 }}
            control={
              <Switch
                checked={settingsData?.IsItvEnabled}
                defaultChecked={settingsData?.IsItvEnabled}
                disabled={!permission.edit}
                onChange={({ target }) => {
                  if (!target.checked) {
                    setOpenApprove(true);
                  } else {
                    setSettingsData({
                      ...settingsData,
                      IsItvEnabled: target.checked,
                    });
                  }
                }}
              />
            }
            label="Использование системы ITV"
          />
        )}
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <TextField
              label="Максимальное количество камер"
              sx={{ width: '250px' }}
              type="number"
              name="MaxCameraCount"
              onWheel={(e) => e.target.blur()}
              disabled={!permission.edit || !settingsData.IsItvEnabled}
              InputLabelProps={{
                shrink: true,
              }}
              required={config.MaxCameraCount.isRequired}
              value={settingsData?.MaxCameraCount?.toString() || 0}
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, MaxCameraCount: +value || 0 })
              }
            />
            <TextField
              label="URL сервиса ITV"
              sx={{ width: '500px' }}
              type="url"
              name="ItvServiceUrl"
              onWheel={(e) => e.target.blur()}
              disabled={!permission.edit || !settingsData.IsItvEnabled}
              InputLabelProps={{
                shrink: true,
              }}
              required={config.ItvServiceUrl.isRequired}
              value={settingsData?.ItvServiceUrl?.toString()}
              onChange={({ target: { value } }) =>
                setSettingsData({ ...settingsData, ItvServiceUrl: value || '' })
              }
            />
            <Button
              variant="outlined"
              sx={{ width: '103px' }}
              disabled={!permission.edit || !settingsData?.IsItvEnabled}
              onClick={() => {
                
              }}
            >
              Задать
            </Button>
          </Stack>
      </FormValidator>

      <ActionApproveV2
          open={openApprove}
          title="Вы действительно хотите отключить использование системы ITV для этой организации?"
          handleClose={() => setOpenApprove(false)}
          showDescription={false}
          handleSuccess={() => {
            setSettingsData({
              ...settingsData,
              IsItvEnabled: false,
            });
            
            setOpenApprove(false);
          }}
          actionButtonText="Да"
          handleCloseText="Нет"
        />
    </FullPageLayout>
  );
};
