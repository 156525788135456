import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { URLS } from '../../utils/urls.util';
import { useNavigate } from 'react-router-dom';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';

export const SingleCompanyNavigation = ({
  company,
  activeTab,
  wasChange,
  appSettings,
}) => {
  const [openApprove, setOpenApprove] = useState(false);
  const [navigateLink, setNavigateLink] = useState('');
  const navigate = useNavigate();

  return (
    <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
      <Button
        variant={activeTab == 1 ? 'outlined' : 'text'}
        color="primary"
        onClick={() => {
          if (wasChange) {
            setNavigateLink(URLS.COMPANIES + '/' + company.id + '/data');
            setOpenApprove(true);
          } else {
            navigate(URLS.COMPANIES + '/' + company.id + '/data');
          }
        }}
      >
        Данные для АРМ
      </Button>
      {appSettings.isPaymentEnabled && (
        <Button
          variant={activeTab == 2 ? 'outlined' : 'text'}
          color="primary"
          onClick={() => {
            if (wasChange) {
              setNavigateLink(URLS.COMPANIES + '/' + company.id + '/details');
              setOpenApprove(true);
            } else {
              navigate(URLS.COMPANIES + '/' + company.id + '/details');
            }
          }}
          disabled={!company?.id}
        >
          Реквизиты
        </Button>
      )}
      <Button
        variant={activeTab == 3 ? 'outlined' : 'text'}
        color="primary"
        onClick={() => {
          if (wasChange) {
            setNavigateLink(URLS.COMPANIES + '/' + company.id + '/rates');
            setOpenApprove(true);
          } else {
            navigate(URLS.COMPANIES + '/' + company.id + '/rates');
          }
        }}
        disabled={!company?.id}
      >
        Тарифы для абонентов
      </Button>
      <Button
        variant={activeTab == 4 ? 'outlined' : 'text'}
        color="primary"
        onClick={() => {
          if (wasChange) {
            setNavigateLink(URLS.COMPANIES + '/' + company.id + '/employees');
            setOpenApprove(true);
          } else {
            navigate(URLS.COMPANIES + '/' + company.id + '/employees');
          }
        }}
        disabled={!company?.id}
      >
        Сотрудники
      </Button>
      <Button
        variant={activeTab == 5 ? 'outlined' : 'text'}
        color="primary"
        onClick={() => {
          if (wasChange) {
            setNavigateLink(URLS.COMPANIES + '/' + company.id + '/sms');
            setOpenApprove(true);
          } else {
            navigate(URLS.COMPANIES + '/' + company.id + '/sms');
          }
        }}
        disabled={!company?.id}
      >
        SMS-рассылки
      </Button>
      <Button
        variant={activeTab == 6 ? 'outlined' : 'text'}
        color="primary"
        onClick={() => {
          if (wasChange) {
            setNavigateLink(URLS.COMPANIES + '/' + company.id + '/settings');
            setOpenApprove(true);
          } else {
            navigate(URLS.COMPANIES + '/' + company.id + '/settings');
          }
        }}
        disabled={!company?.id}
      >
        Настройки
      </Button>

      <ActionApproveV2
        open={openApprove}
        title="У вас есть несохраненные данные. Перейти без сохранения?"
        handleClose={() => setOpenApprove(false)}
        showDescription={false}
        handleSuccess={() => navigate(navigateLink)}
        handleSuccessText="Да"
        handleCloseText="Нет"
      />
    </Stack>
  );
};
