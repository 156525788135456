import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { editCompnaySms, getOneCompany } from '../../api/CompanyApi';

import { URLS } from '../../utils/urls.util';

import { useApi } from '../../hooks/useApi';

import { getAppSettings } from '../../api/AppSettings';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import { SingleCompanyNavigation } from './SingleCompanyNavigation';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { CompanySmsValidationConfig } from '../../utils/formValidatorConfigs/config';
import { FormValidator } from '../FormValidator/FormValidator';

export const SingleCompanySms = ({ id, permission }) => {
  const getOneCompanyApi = useApi(getOneCompany);
  const getAppSettingsApi = useApi(getAppSettings);
  const [isCreate, setIsCreate] = useState(false);
  const [company, setCompany] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [wasChange, setChange] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const editCompnaySmsApi = useApi(editCompnaySms);
  const [validateErrors, setValidateErrors] = useState([]);
  const [render, setRender] = useState(false);
  const [smsData, setSmsData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (id !== 'create') {
      getOneCompanyApi.sendRequest(id, ['meta']).then((company) => {
        setCompany(company);
        setSmsData({
          Id: company.id,
          IsSmsMailingEnabled: company.isSmsMailingEnabled,
          SmsCount: company.smsCount,
        });
      });
    }
    getAppSettingsApi.sendRequest().then((result) => setAppSettings(result));
  }, [id]);

  useEffect(() => {
    if (!!Object.keys(smsData)?.length) {
      setRender(true);
    }
  }, [smsData]);

  useState(() => {
    if (id == 'create') {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [id, company]);

  const config = useMemo(() => CompanySmsValidationConfig(), []);
  const handlerError = (data) => {
    setValidateErrors(data);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.COMPANIES}>
      Организации
    </Link>,
    <Typography key="3" color="text.primary">
      {company.title}
    </Typography>,
  ];

  return (
    (id || isCreate) && (
      <FullPageLayout
        obj={company}
        seoTitle={company?.title}
        pageTitle={company.title}
        isCreated={id}
        breadcrumbs={breadcrumbs}
        showHiddenData={true}
      >
        <SingleCompanyNavigation
          company={company}
          activeTab={5}
          wasChange={wasChange}
          appSettings={appSettings}
        />

        <FormValidator
          name="company-details-form-name"
          validationConfig={config}
          changed={smsData}
          setErrorsHandler={(data) => handlerError(data)}
        >
          <Typography sx={{ mt: 3 }} variant="h5">
            SMS-рассылки
          </Typography>
          {!!Object.keys(smsData)?.length && render && (
            <FormControlLabel
              sx={{ mt: 3 }}
              control={
                <Switch
                  checked={smsData?.IsSmsMailingEnabled}
                  defaultChecked={smsData?.IsSmsMailingEnabled}
                  disabled={!permission.edit}
                  onChange={({ target }) => {
                    if (!target.checked) {
                      setOpenApprove(true);
                    } else {
                      setSmsData({
                        ...smsData,
                        IsSmsMailingEnabled: target.checked,
                      });
                    }
                  }}
                />
              }
              label="Подключение SMS-рассылок "
            />
          )}
          <Typography sx={{ mt: 3, color: '#949494' }}>
            Укажите количество SMS для данной организации, которое будет
            выделяться автоматически каждый календарный месяц. В случае
            необходимости, вы можете изменить лимит SMS на месяц. Обратите
            внимание, если организация запланировала рассылку большего
            количества SMS до уменьшения лимита, рассылка все равно будет
            осуществлена.
          </Typography>

          <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
            <TextField
              label="Количество SMS в месяц"
              sx={{ width: '410px' }}
              type="number"
              name="SmsCount"
              onWheel={(e) => e.target.blur()}
              disabled={!permission.edit || !smsData?.IsSmsMailingEnabled}
              InputLabelProps={{
                shrink: true,
              }}
              required={config.SmsCount.isRequired}
              value={smsData?.SmsCount?.toString() || 0}
              onChange={({ target: { value } }) =>
                setSmsData({ ...smsData, SmsCount: +value || 0 })
              }
            />
            <Button
              variant="outlined"
              sx={{ width: '103px' }}
              disabled={!permission.edit || !smsData?.IsSmsMailingEnabled}
              onClick={() => {
                editCompnaySmsApi.sendRequest(smsData).then((result) =>
                  dispatch(
                    setAlert({
                      text: 'SMS рассылки обновлены',
                      status: 200,
                    }),
                  ),
                );
              }}
            >
              Задать
            </Button>
          </Stack>
        </FormValidator>

        <ActionApproveV2
          open={openApprove}
          title="Вы действительно хотите отключить SMS-рассылки для этой организации?"
          handleClose={() => setOpenApprove(false)}
          showDescription={false}
          handleSuccess={() => {
            setSmsData({
              ...smsData,
              IsSmsMailingEnabled: false,
            });
            editCompnaySmsApi
              .sendRequest({
                ...smsData,
                IsSmsMailingEnabled: false,
              })
              .then((result) =>
                dispatch(
                  setAlert({
                    text: 'SMS рассылки обновлены',
                    status: 200,
                  }),
                ),
              );
            setOpenApprove(false);
          }}
          actionButtonText="Да"
          handleCloseText="Нет"
        />
      </FullPageLayout>
    )
  );
};
